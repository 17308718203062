















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ArchiveEntry } from './types';
import { LineHistory } from '@/components/archive/lines';
import { InvoiceService } from '@/lib/services';

@Component({
  components: {
    LineHistory,
  },
  filters: {},
})
export default class CustomerHistory extends Vue {
  @Prop({ required: true, type: Number })
  customerId: number;

  entries: ArchiveEntry[] = [];
  loading: boolean = true;

  async mounted(): Promise<void> {
    await this.refresh();
  }

  @Watch('customerId')
  async refresh(): Promise<void> {
    this.loading = true;

    const results = await InvoiceService.search(0, {
      customer_id: this.customerId,
      limit: 5,
      type: 'invoice',
    });

    this.entries = results.items.map((e) => ({
      id: e ? e.id : '',
      selected: false,
      data: e,
    }));

    this.loading = false;
  }

  get archiveLink(): Record<string, any> {
    return {
      name: 'archive',
      query: {
        type: 'invoice',
        customer_id: this.customerId,
      },
    };
  }
}
