






















































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Customer } from '@/models';

import { CustomerService } from '@/lib/services';
import { debounce } from 'lodash-es';
import Pagination from '@/components/Pagination.vue';

@Component({
  components: { Pagination },
})
export default class CustomerList extends Vue {
  customers: Customer[];
  selectedCustomerId: number | null = null;
  limit: number = 100;
  totalEntries: number = 0;
  totalPages: number = 0;
  searchString: string = '';
  resolvingSelectedCustomer: boolean = false;

  private customerMap: [string, Customer[]][] = [];

  updatePage = debounce((x: number = 1) => this.updateList(x), 500);

  async created(): Promise<void> {
    await this.updateList();
    this.selectCustomerFromRoute();
  }

  @Watch('$route')
  async selectCustomerFromRoute(): Promise<void> {
    // We need this just to prevent flashing page when resolving a customer that
    // is not part of first page
    this.resolvingSelectedCustomer = true;
    if (!this.customers) {
      // customer's haven't finished loading yet. Stop here, the created() call will update it.
      return;
    }

    try {
      if (this.$route.query['select']) {
        const wantedId = +this.$route.query['select'];
        const customer = this.customers.find(
          (customer) => customer.id === wantedId,
        );
        if (customer) {
          this.select(customer);
        } else {
          // If customer is not visible, maybe it's just not loaded
          // We can try to fetch it directly from backend
          const fetchedCustomer = await CustomerService.get(wantedId);
          if (fetchedCustomer) {
            this.customers = [fetchedCustomer];
            this.totalEntries = 1;
            this.totalPages = 1;
            this.generateMap();
            this.select(fetchedCustomer);
            // This gives the user an easy way to get back to full list of customers
            this.searchString = fetchedCustomer.name;
          } else {
            // If we can't resolve customer, flash message for the user
            this.$toaster.error(this.$tc('messages.error.load.customer'));
          }
        }
      }
    } finally {
      this.resolvingSelectedCustomer = false;
      // Lets clear select param so that user has a clean
      // slate if they want to refresh the page
      void this.$router
        .replace({
          query: { ...this.$route.query, select: undefined },
        })
        .catch((e) => e);
    }
  }

  async updatePageLimit(limit: number): Promise<void> {
    this.limit = limit;
    await this.updatePage(1);
  }

  public async updateList(page: number = 1): Promise<void> {
    const results = await CustomerService.search(page, {
      limit: +this.limit,
      ...(this.searchString.length > 0 && { term: this.searchString }),
    });
    this.customers = results.items;
    this.totalEntries = results.total_items;
    this.totalPages = results.last;
    this.generateMap();
  }

  select(customer: Customer): void {
    this.selectedCustomerId = customer.id;
    this.$emit('select', customer);
  }

  generateMap(): void {
    const customers = this.customers.sort((x, y) => {
      const a = x.name.toLowerCase();
      const b = y.name.toLowerCase();

      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });

    const map: Map<string, Customer[]> = new Map();

    for (const customer of customers) {
      if (customer.name.length < 1) {
        continue;
      }

      const key = customer.name[0].toUpperCase();

      if (!map.has(key)) {
        map.set(key, []);
      }

      map.get(key)!.push(customer);
    }

    this.customerMap = Array.from(map);
  }
}
