






































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Customer } from '@/models';
import CustomerCard from '@/components/CustomerCard.vue';
import CustomerList from '@/components/CustomerList.vue';
import { CustomerEditModal } from '@/components/modals';
import Fab from '@/components/Fab.vue';
import { CustomerService } from '@/lib/services';
import Papa from 'papaparse';
import { downloadBlob } from '@/lib/download';

@Component({
  components: {
    CustomerCard,
    CustomerList,
    CustomerEditModal,
    Fab,
  },
})
export default class CustomerPage extends Vue {
  selected: Customer | null = null;
  showDetails: boolean = false;
  showNew: boolean = false;
  showEdit: boolean = false;
  exportInProgress: boolean = false;

  onSelect(customer: Customer): void {
    this.selected = customer;
    this.showDetails = true;
    this.showNew = false;
    this.showEdit = false;
  }

  onBack(): void {
    this.showDetails = this.showNew = this.showEdit = false;
  }

  editSelected(): void {
    this.showNew = false;
    this.showEdit = true;
  }

  openNew(): void {
    this.showEdit = false;
    this.showNew = true;
  }

  async exportCustomers(): Promise<void> {
    if (!this.exportInProgress) {
      this.exportInProgress = true;
      const customers = await CustomerService.all();
      const csv = Papa.unparse(
        customers.map((e) => {
          const x = e.serialize();
          delete x.id;
          delete x.company_id;
          return x;
        }),
      );
      const file = new File([csv], 'customers.csv');
      downloadBlob(file);
      this.exportInProgress = false;
    }
  }

  goToImport(): void {
    this.$router.push({ name: 'customers_import' });
  }

  goToDeduplication(): void {
    this.$router.push({ name: 'customers_merge' });
  }

  onDelete(): void {
    (this.$refs['customerlist'] as CustomerList).updateList();
    this.showDetails = false;
  }

  onCreated(customer: Customer): void {
    (this.$refs['customerlist'] as CustomerList).updateList();
    this.onSelect(customer);
  }
}
