







































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Customer } from '@/models';
import { CustomerService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

import Invoices from '@/components/archive/Invoices.vue';
import CustomerHistory from '@/components/archive/CustomerHistory.vue';

@Component({
  components: {
    CustomerHistory,
    Invoices,
  },
})
export default class CustomerCard extends Vue {
  @Prop({ required: true })
  customer: Customer | null;
  @Prop({ default: false })
  showBack: boolean;
  @Prop({ default: false })
  showEdit: boolean;
  @Prop({ default: false })
  showDelete: boolean;
  @Prop({ default: false, type: Boolean })
  showHistory: boolean;

  back(): void {
    this.$emit('back');
  }

  edit(): void {
    this.$emit('edit');
  }

  async del(): Promise<void> {
    if (!this.customer) return;
    try {
      await CustomerService.delete(this.customer.id);
      this.$toaster.success(
        this.$tc('messages.success.delete.customer'),
        this.customer.name,
      );
      this.$emit('del');
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.delete.customer'),
        unwrapError(e),
      );
    }
  }
}
